import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { SSOTProvider } from './context';
import { AuthProvider } from './context/auth/AuthContext';
import { RecimetApp } from './RecimetApp';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <SSOTProvider>
        <AuthProvider>
          <RecimetApp />
        </AuthProvider>
      </SSOTProvider>
    </BrowserRouter>
  </React.StrictMode>
);
