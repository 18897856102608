import React, { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import '../pages/_shared/Styles/Styles.css';
const PrincipalRoutes = lazy(() =>
  import('../pages/principal/routes/PrincipalRoutes')
);
const AuthRoutes = lazy(() => import('../pages/auth/routes/AuthRoutes'));

// Importar rutas de las paginas o secciones

export const AppRouter = () => {
  return (
    <>
      <React.Suspense fallback=''>
        <Routes>
          <Route path='/*' element={<PrincipalRoutes />} />
          <Route path='/auth/*' element={<AuthRoutes />} />
          <Route path='/index.php' element={<Navigate to='/' />} />
        </Routes>
      </React.Suspense>
    </>
  );
};
