import React, { createContext, useState } from 'react';

//________________________________________________________________________________________
//________________________________________________________________________________________
const SSOTContext = createContext();
//----------------------------------------------------------------------------------------

//________________________________________________________________________________________
//________________________________________________________________________________________
export const SSOTProvider = ({ children }) => {
  //______________________________________________________________________________________
  //____Auth______________________________________________________________________________
  const [isLogged, setIsLogged] = useState(false);
  const [recordarme, setRecordarme] = useState(false);
  //--------------------------------------------------------------------------------------
  const [pass, setPass] = useState('');
  const [user, setUser] = useState('');

  //____Cotizacion________________________________________________________________________
  const [alertFoto, setAlertFoto] = useState(false);
  const [alertIgualProducto, setAlertIgualProducto] = useState(false);
  const [alertLimiteAccesos, setAlertLimiteAccesos] = useState(false);
  const [alertEnviandoCotizacion, setAlertEnviandoCotizacion] = useState(false);
  const [alertCotizacionEnviada, setAlertCotizacionEnviada] = useState(false);
  const [alertCotizacionEnvioFalla, setAlertCotizacionEnvioFalla] =
    useState(false);
  const [alertLimiteAccesosConsultas, setAlertLimiteAccesosConsultas] =
    useState(false);
  const [cantidadPorKilo, setCantidadPorKilo] = useState(false);

  const [mostrarPrecio, setMostrarPrecio] = React.useState(false);
  const [mostrarPrecioConsultas, setMostrarPrecioConsultas] =
    React.useState(false);
  const [controlAccesos, setControlAccesos] = useState(false);
  const [controlAccesosConsultas, setControlAccesosConsultas] = useState(false);
  const [cantidadDeConsultas, setCantidadDeConsultas] = useState(
    JSON.parse(sessionStorage.getItem('sumaAccesos'))?.accesoComprador
  );
  const [selected, setSelected] = useState([]);
  //--------------------------------------------------------------------------------------
  const [botonEstado, setBotonEstado] = useState();
  const [productoValue, setProductoValue] = useState('');

  //____Resumen___________________________________________________________________________
  const [cart, setCart] = useState([]);

  // data: states & functions exported by the provider
  const data = {
    //---Auth---
    isLogged,
    setIsLogged,
    recordarme,
    setRecordarme,
    pass,
    setPass,
    user,
    setUser,

    //---Cotizacion---
    productoValue,
    setProductoValue,
    botonEstado,
    setBotonEstado,
    alertFoto,
    setAlertFoto,
    alertIgualProducto,
    setAlertIgualProducto,
    mostrarPrecio,
    setMostrarPrecio,
    controlAccesos,
    setControlAccesos,
    alertLimiteAccesos,
    setAlertLimiteAccesos,
    selected,
    setSelected,
    controlAccesosConsultas,
    setControlAccesosConsultas,
    mostrarPrecioConsultas,
    setMostrarPrecioConsultas,
    cantidadDeConsultas,
    setCantidadDeConsultas,
    alertLimiteAccesosConsultas,
    setAlertLimiteAccesosConsultas,
    alertEnviandoCotizacion,
    setAlertEnviandoCotizacion,
    alertCotizacionEnviada,
    setAlertCotizacionEnviada,
    alertCotizacionEnvioFalla,
    setAlertCotizacionEnvioFalla,
    cantidadPorKilo,
    setCantidadPorKilo,

    //---Resumen---
    cart,
    setCart,
  };

  return <SSOTContext.Provider value={data}>{children}</SSOTContext.Provider>;
};

export default SSOTContext;
