import recimetApi from './recimetApi';

export const contadorConsultasHelper = async () => {
  // traer la cantidad de consultas que tiene actualmente(accesos)
  const urlAxiosRequest =
    'compradores/' + JSON.parse(sessionStorage.getItem('session') || localStorage.getItem('session')).idComprador;

  try {
    await recimetApi
      .get(urlAxiosRequest, {
        headers: {
          Authorization: JSON.parse(sessionStorage.getItem('session') || localStorage.getItem('session')).jwt,
        },
      })
      .then((response) => {
        const accesoComprador = response.data.Accesos;
        const topeAccesoComprador = response.data.TopeAccesos;
        sessionStorage.setItem(
          'sumaAccesos',
          JSON.stringify({
            accesoComprador: accesoComprador,
            topeAccesoComprador: topeAccesoComprador,
          })
        );
      });
  } catch (error) {
    throw error;
  }
};
