import recimetApi from './recimetApi';

export const envioContactoHelper = async (item) => {
  const urlAxiosRequest = 'comunicaciones/envioMailContacto';

  try {
    await recimetApi.post(urlAxiosRequest, item);
  } catch (error) {
    throw error;
  }
};
