import React, { createContext, useContext, useEffect } from 'react';
import { contadorConsultasHelper } from '../../helpers';
import { marcasProductosHelper } from '../../helpers/marcasProductosHelper';
import { productosHelper } from '../../helpers/productosHelper';
import SSOTContext from '../SSOT';
import recimetApi from '../../helpers/recimetApi';

//________________________________________________________________________________________
//________________________________________________________________________________________
const AuthContext = createContext();

//________________________________________________________________________________________
//________________________________________________________________________________________
export const AuthProvider = ({ children }) => {
  //______________________________________________________________________________________
  const { isLogged, setIsLogged, user, pass, recordarme } = useContext(SSOTContext);

  // Boton del loguin / manejo del user
  const loginHandler = async () => {
    try {

      const {data} = await recimetApi.post('auth/login', {
        usuario: user,
        contraseña: pass,
      });

      if (data.Activo === 'S') {
        //crear constante donde almacenar los datos recibidos
        const session = {
          jwt: data.jwt,
          email: data.Email,
          username: data.Usuario,
          idComprador: data.IdCompradores,
          celular: data.Telefono,
          comision: data.Comision,
        };
        if (recordarme === true) {
          //almacenar valores al localStorage creando el key:session
          localStorage.setItem('session', JSON.stringify(session));
          // Esta logeado, setea estado
          setIsLogged(true);
        } else if (recordarme === false) {
          //almacenar valores al sessionStorage creando el key:session
          sessionStorage.setItem('session', JSON.stringify(session));
          // Esta logeado, setea estado
          setIsLogged(true);
        }
      }
    } catch (error) {
      alert('Usuario o Contraseña Incorrecta!');
    }
  };

  // control para conocer el estado del loggin
  const isLoggedOk = async () => {
    if (
      JSON.parse(localStorage.getItem('session')) !== null ||
      JSON.parse(sessionStorage.getItem('session')) !== null
    ) {
      setIsLogged(true);
    }
  };

  useEffect(() => {
    isLoggedOk();
  }, [isLogged]);

  // trae la tabla producto, marcas y sumaAccesos del backend
  useEffect(() => {
    if (isLogged === true) {
      if (JSON.parse(sessionStorage.getItem('tablaProductos')) === null) {
        productosHelper();
      }
      if (JSON.parse(sessionStorage.getItem('tablaMarcas')) === null) {
        marcasProductosHelper();
      }
      if (JSON.parse(sessionStorage.getItem('sumaAccesos')) === null) {
        contadorConsultasHelper();
      }
    }
  }, [isLogged]);

  // data: states & functions exported by the provider
  const data = {
    loginHandler,
    isLoggedOk,
  };

  return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>;
};

export default AuthContext;
