import recimetApi from './recimetApi';

export const marcasProductosHelper = async () => {
  const urlAxiosRequest = 'claves';
  try {
    await recimetApi
      .get(urlAxiosRequest, {
        headers: {
          Authorization: JSON.parse(sessionStorage.getItem('session') || localStorage.getItem('session')).jwt,
        },
      })
      .then((response) => {
        const tablaProducto = response.data;
        const filter = (event) => {
          return event.IdClaves.indexOf('M') === 0;
        };
        const data = tablaProducto.filter(filter);
        if (data.length !== 0) {
          sessionStorage.setItem('tablaMarcas', JSON.stringify(data));
        }
      });
  } catch (error) {
    throw error;
  }
};
